import "./image.css";
import * as dompack from 'dompack';
import * as preload from 'dompack/extra/preload';

class cEnLargeImage
{
  constructor( node )
  {
    if( document.documentElement.classList.contains("printpage") )
      return;

    this.node = node;

    this.animtimer = 300;//ms

    this.closefn = this.hideLargeImage.bind(this);
    this.resizefn = this.onResize.bind(this);
    this.menufn = this.showMenu.bind(this);

    node.addEventListener("click", ev => this.showLargeImage(ev) );
  }

  onResize()
  {
    if( !this.overlay || !this.largeimage )
      return;

    if( this.largeimage.node.parentNode )
    {
      this.largeimage.node.style.height = "";
      let maxh = this.largeimage.node.parentNode.clientHeight;
      if( this.largeimage.height > maxh )
        this.largeimage.node.style.height = maxh + "px";
    }

    this.viewsize = this.largeimage.node.getBoundingClientRect();

    dompack.toggleClass(this.overlay, "enablemagnify", this.largeimage.width > this.viewsize.width || this.largeimage.height > this.viewsize.height );

    this.removeMagnifyArea();
  }

  showMenu( ev )
  {
    if( !this.overlay )
      return;

    ev.preventDefault();
    if( !this.menunode )
    {
      this.menunode = <div class="downloadmenu">
                        <a href={this.node.dataset.download} download={this.node.dataset.name}>
                          <span class="icon fas fa-cloud-download"></span>
                          Download
                        </a>
                      </div>;//

      this.overlay.appendChild(this.menunode);
    }

    this.menunode.style.top = ev.clientY + "px";
    this.menunode.style.left = ev.clientX + "px";
  }

  hideMenu()
  {
    if( this.menunode )
    {
      this.menunode.parentNode.removeChild(this.menunode);
      this.menunode = null;
    }
  }

  showLargeImage( ev )
  {
    dompack.stop(ev);
    clearTimeout(this.hidetimer);

    this.overlay = <div class="largeimage-overlay" />;//
    if( this.largeimage )
      this.addLargeImage();
    else
      this.overlay.appendChild(<div class="loading"><span class="fal fa-spinner-third fa-spin" /></div>);//

    document.body.appendChild(this.overlay);
    this.overlay.clientWidth;//force css update
    this.overlay.classList.add("show");

    this.onResize();

    window.addEventListener("resize", this.resizefn );
    window.addEventListener("keyup", this.closefn );
    window.addEventListener("click", this.closefn );
    document.addEventListener('contextmenu', this.menufn );

    if( !this.largeimage )
      this.preloadImage();
    else
    {
      this.largeimage.node.parentNode.classList.remove("beforeshow");
      setTimeout(this.onResize.bind(this),this.animtimer);
    }
  }

  addLargeImage()
  {
    this.overlay.appendChild( <div class="image beforeshow" style={"transition: opacity " + this.animtimer + "ms, transform " + this.animtimer + "ms"}>
                                {this.largeimage.node}
                                <span class="magnify">
                                  <span class="show far fa-search-plus" />
                                  <span class="hide far fa-search-minus" />
                                </span>
                                <span class="close fal fa-times"></span>
                              </div>);//

  }

  hideLargeImage(ev)
  {
    if( ev )
    { //check if ESC is pressed
      if( ev.type == "keyup" )
      {
        if( ev.keyCode != 27 )
          return;
        if( this.menunode )
        {
          this.hideMenu();//then just hide download link
          return;
        }
        else if( this.magnifynode )
        {
          this.removeMagnifyArea();//Just remove magnifynode
          return;
        }
      }

      if( ev.type == "click" )
      {
        if( this.menunode )
        {
          this.hideMenu();//then just hide download link
          return;
        }
        else if( dompack.closest(ev.target, ".magnify" ) || dompack.closest(ev.target, ".largeimage-overlay__magnify" ))
        {
          if( !this.magnifynode )
          {
            let zoomimg = this.largeimage.node.cloneNode();
            zoomimg.style.width = this.largeimage.width + "px";
            zoomimg.style.height = this.largeimage.height + "px";
            this.magnifynode = <div class="largeimage-overlay__magnify">{zoomimg}</div>;//
            this.largeimage.node.parentNode.appendChild(this.magnifynode);
            this.largeimage.node.parentNode.classList.add("magnifyactive");

            this.viewsize = this.largeimage.node.getBoundingClientRect();
            this.magnifysize = { width: this.magnifynode.clientWidth
                               , height: this.magnifynode.clientHeight
                               };

            this.movefn = this.updateMagnifyArea.bind(this);

            if( window.event_supportstouch )
            {
              this.magnifynode.addEventListener("touchstart", ev => {
                dompack.stop(ev);
                this.magnifynode.addEventListener("touchmove", this.movefn );
              });

              this.magnifynode.addEventListener("touchend", ev => {
                dompack.stop(ev);
                this.magnifynode.removeEventListener("touchmove", this.movefn );
              });
            }

            this.magnifynode.addEventListener("mousedown", ev => {
              dompack.stop(ev);
              this.magnifynode.addEventListener("mousemove", this.movefn );
            });

            this.magnifynode.addEventListener("mouseup", ev => {
              dompack.stop(ev);
              this.magnifynode.removeEventListener("mousemove", this.movefn );
            });
            this.updateMagnifyArea(ev, true);
          }
          else if( dompack.closest(ev.target, ".magnify" ) )
          {
            this.removeMagnifyArea();
          }

          return;
        }
        else if( this.magnifynode )
        { //Just remove magnifynode
          this.removeMagnifyArea();
          return;
        }
      }
    }

    this.hideMenu();

    if( this.overlay )
    {
      this.largeimage.node.parentNode.classList.add("beforeshow");
      this.largeimage.node.parentNode.clientWidth;//force css update
      this.hidetimer = setTimeout(function(){
        this.overlay.parentNode.removeChild(this.overlay);
        this.overlay = null;
      }.bind(this), this.animtimer);
    }

    window.removeEventListener("resize", this.resizefn );
    window.removeEventListener("keyup", this.closefn );
    window.removeEventListener("click", this.closefn );
    document.removeEventListener("contextmenu", this.menufn );
  }

  removeMagnifyArea()
  {
    if( this.magnifynode )
      this.magnifynode.parentNode.removeChild(this.magnifynode);
    this.largeimage.node.parentNode.classList.remove("magnifyactive");
    this.magnifynode = null;
  }

  updateMagnifyArea( ev, center )
  {
    if( !this.magnifynode )
      return;

    let x;
    let y;

    if( center )
    {
      x = this.viewsize.width/2 - this.magnifysize.width/2;
      y = this.viewsize.height/2 - this.magnifysize.height/2;
    }
    else if( ev.type == "touchmove" )
    {
      x = ev.touches[0].clientX - this.viewsize.left - this.magnifysize.width/2;
      y = ev.touches[0].clientY - this.viewsize.top - this.magnifysize.height/2;
    }
    else
    {
      x = ev.clientX - this.viewsize.left - this.magnifysize.width/2;
      y = ev.clientY - this.viewsize.top - this.magnifysize.height/2;
    }

    //Bounds detection
    if( x < 0 )
      x = 0;
    else if( x + this.magnifysize.width > this.viewsize.width )
      x = this.viewsize.width - this.magnifysize.width;
    if( y < 0 )
      y = 0;
    else if( y + this.magnifysize.height > this.viewsize.height )
      y = this.viewsize.height - this.magnifysize.height;

    this.magnifynode.style.top = ~~y + "px";
    this.magnifynode.style.left = ~~x + "px";

    //Move image inside magnifier
    let rangex = this.viewsize.width - this.magnifysize.width;
    let rangey = this.viewsize.height - this.magnifysize.height;
    let percx = x / rangex;
    let percy = y / rangey;
    x = percx*(this.largeimage.width - this.magnifysize.width);
    y = percy*(this.largeimage.height - this.magnifysize.height);
    this.magnifynode.children[0].style.transform = "translate3d(-" + ~~x + "px,-" + ~~y + "px,0)";
  }

  async preloadImage()
  {
    let preloadedimage = await preload.promiseImage( this.node.dataset.image );
    if( preloadedimage )
    {
      this.largeimage = preloadedimage;

      if( this.overlay )
      {
        dompack.empty( this.overlay );
        this.addLargeImage();
        this.onResize();

        this.largeimage.node.parentNode.classList.remove("beforeshow");
        setTimeout(this.onResize.bind(this),this.animtimer);
      }
    }
  }
}

dompack.register(".embeddedwidget-image", node => new cEnLargeImage(node) );
