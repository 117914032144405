import "./footnote.css";
import "./footnote-content.scss";
import * as dompack from "dompack";

class cFootnote
{
  constructor( node )
  {
    if( document.documentElement.classList.contains("printpage") )
      return;

    this.fadeduration = 600; //ms

    this.node = node;
    this.id = node.dataset.footnote;
    this.contentnode = document.querySelector(".footnoteslist li[data-footnote='" + this.id + "'] > .footnote-description");
    if( !this.contentnode )
    {
      console.error("Missing footnote content (" + this.id + ")");
      return;
    }

    this.node.addEventListener("click", ev => this.showContent(ev));
 //   this.node.addEventListener("mousemove", ev => this.showContent(ev));
 //   this.node.addEventListener("mouseout", ev => this.hideContent(ev));

    this.closefn = this.hideContent.bind(this);
  }

  hideContent( ev )
  {
    if( !this.infowindow )
      return;

    if( ev )
    { //check if ESC or click outside toolbar or hide btn
      if( ev.type == "keyup" && ev.keyCode != 27 )
        return;
      else if( ev.type == "click")
      {
        let chk = dompack.closest(ev.target, ".footnote-infowindow");
        if( ev.target == this.node || chk == this.infowindow )
          return;
      }
    }

    this.infowindow.classList.remove("active");

    this.timer = setTimeout(function(){
      if( this.infowindow )
        this.infowindow.parentNode.removeChild(this.infowindow);
      this.infowindow = null;

      document.body.removeEventListener("click", this.closefn);
      window.removeEventListener("resize", this.closefn);
      window.removeEventListener("keydown", this.closefn);
      document.body.removeEventListener("touchend", this.closefn);
    }.bind(this), this.fadeduration);
  }

  showContent(ev)
  {
    if( ev )
      ev.preventDefault();

    if( this.infowindow )
    {
      clearTimeout(this.timer);
      this.infowindow.classList.add("active");
      return;
    }

    this.infowindow = <div class="footnote-infowindow" />;//
    this.infowindow.style.transition = "opacity " + this.fadeduration + "ms";
    document.body.appendChild(this.infowindow);

    let content = this.contentnode.cloneNode(true);
    let nrnode = this.contentnode.previousSibling;
    if( nrnode && content.children.length)
      content.children[0].insertBefore( nrnode.cloneNode(true), content.children[0].firstChild );

    this.infowindow.appendChild( content );

    let pos = this.node.getBoundingClientRect();

    let centeroffsetx = this.node.clientWidth * 0.5;
    let posx = pos.left + centeroffsetx;

    let scrolloffset = document.body.getBoundingClientRect().top;

    this.infowindow.style.left = posx + "px";
    this.infowindow.style.top = (pos.top - scrolloffset) + "px";

    this.infowindow.clientWidth;//force css update
    this.infowindow.classList.add("active");

    //Check left/right bounds window
    let winx = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    let leftspace = winx - (0.5*content.clientWidth + posx);

    let offsetx = posx;
    if( leftspace < 0 )
      offsetx += leftspace;

    let rightspace = offsetx - 0.5*content.clientWidth;
    if( rightspace < 0 )
      offsetx -= rightspace;

    content.style.marginLeft = (offsetx - posx) + 'px';

    document.body.addEventListener("click", this.closefn);
    window.addEventListener("resize", this.closefn);
    window.addEventListener("keydown", this.closefn);
    document.body.addEventListener("touchend", this.closefn);
  }
}

dompack.register(".inlinewidget-footnote[data-footnote]", node => new cFootnote(node) );
