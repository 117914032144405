import "./header.scss";
import * as dompack from 'dompack';
import * as service from "../service/printservice.rpc.json";
import * as whintegration from '@mod-system/js/wh/integration';
import * as cookie from "dompack/extra/cookie";
import * as domevents from "dompack/src/events";

dompack.register(".mobilemenu-toggle", node => {
  node.addEventListener("click", ev => {
    document.documentElement.classList.toggle("showmenu");
  });

  document.body.addEventListener("keyup", ev => {
    if( ev.keyCode == 27 && document.documentElement.classList.contains("showmenu") )
      document.documentElement.classList.remove("showmenu");
    else if( ev.keyCode == 13 && node == ev.target && !document.documentElement.classList.contains("showmenu") )
      document.documentElement.classList.add("showmenu");
  });
});

class cToolbar
{
  constructor( node )
  {
    this.node = node;

    this.closefn = this.hidePanel.bind(this);

    this.printcontainer = node.querySelector(".printcontainer");

    this.printform = document.getElementById("printform");
    this.printform.addEventListener("submit", ev => {
      ev.preventDefault();
      this.submitPrintForm();
    });

    document.addEventListener("keyup", ev => {
      if( ev.keyCode == 27 && document.documentElement.classList.contains("waitingfordownload") )
        this.checkActiveDownload(0,true);//cancel
    });

    //Catch link to pdf generator and use print form
    for( let anode of document.querySelectorAll("a[href^='" + whintegration.config.siteroot + "print']") )
    {
      anode.addEventListener("click", ev => {
        dompack.stop(ev);

        let action = this.getUrlParam("printaction", anode.href);
        if( action == "printall" ||  action == "printselected" ||  action == "download" ||  action == "downloadselected" )
        {
          this.printform.printaction.value = action;
          this.printform.target = action.indexOf("download") > -1 ? "" : "print";

          if( action == "printselected" || action == "downloadselected" )
          {
            this.loadPrintData();
            if( !this.cacheddata )
              return;

            this.printform.printdata.value = this.cacheddata;
            this.submitPrintForm();
          }
          else
            this.submitPrintForm();
        }
      });
    }

    node.addEventListener("click", ev => {
      let btn = dompack.closest(ev.target, ".toolbar__item > .button");
      if( !btn )
        return;

      this.btn = btn;

      if( this.btn.parentNode.classList.contains("show") ) //toggle
        this.hidePanel();
      else
        this.showPanel();
    });

    if( this.printcontainer )
      this.printcontainer.parentNode.addEventListener("click", ev => this.handlePrintActions(ev) );
  }

  submitPrintForm(ev)
  {
    this.printform.printtime.value = new Date().getTime();

    if( this.printform.printaction.value.indexOf("download") > -1 )
    {
      clearInterval(this.checktimer);
      document.documentElement.classList.add("waitingfordownload");
      domevents.dispatchCustomEvent(this.printform, 'dompack:busycomponent', { bubbles: true, cancelable: false, detail: { component: this.printform, islock: true, title: "Please wait, generating pdf" } });
      this.checktimer = setInterval(function(){this.checkActiveDownload(this.printform.printtime.value);}.bind(this),200);
    }
    this.printform.submit();
  }

  checkActiveDownload( printtime, cancel )
  {
    let data = printtime ? cookie.read( "cimicdownload-" + printtime ) : "";
    if( data || cancel )
    {
      domevents.dispatchCustomEvent(this.printform, 'dompack:busycomponent', { bubbles: true, cancelable: false, detail: { component: this.printform, islock: false } });
      document.documentElement.classList.remove("waitingfordownload");
      clearInterval(this.checktimer);
    }
  }

  getUrlParam(name, url)
  {
    if( url )
    {
      let parts = url.split("?");
      url = parts.length > 1 ? "?" + parts[1] : "";
    }
    else
      url = location.search;

    if( !url )
      return "";

    let urlparamstr = url.replace(/\+/g,"%20");
    let val  = ( new RegExp('[?&]' + encodeURIComponent(name) + '=([^&]*)') ).exec(urlparamstr);
    return val ? decodeURIComponent(val[1]) : "";
  }

  hidePanel(ev)
  {
    if( !this.btn )
      return;

    if( ev )
    { //check if ESC or click outside toolbar or hide btn
      if( ev.type == "keyup" && ev.keyCode != 27 )
        return;
      else if( ev.type == "click")
      {
        if( dompack.closest(ev.target, ".toolbar") && !ev.target.classList.contains("hide") )
          return;

        if( !dompack.closest(ev.target, "body") )//item probably removed from dom
          return;
      }
    }

    document.body.removeEventListener("keyup", this.closefn );
    document.body.removeEventListener("click", this.closefn );

    this.btn.parentNode.classList.toggle("show");
    this.node.classList.remove("hasactiveitem");

    this.btn = null;
  }

  showPanel()
  {
    document.body.addEventListener("keyup", this.closefn );
    document.body.addEventListener("click", this.closefn );

    if( this.node.classList.contains("hasactiveitem") )
    { //hide other panel if active
      for( let i = 0; i < this.node.children.length; ++i )
        this.node.children[i].classList.remove("show");
    }

    this.btn.parentNode.classList.toggle("show");
    this.node.classList.add("hasactiveitem");

    if( this.btn.parentNode.classList.contains("toolbar__item--print") )
      this.loadPrintlist();
    else if( this.btn.parentNode.classList.contains("toolbar__item--search") )
    {
      let inpnode = this.btn.parentNode.querySelector("input");
      if( inpnode )
        inpnode.focus();
    }
  }

  loadPrintData()
  {
    let data = localStorage.getItem("printids");
    if( this.cacheddata && this.cacheddata == data )
      return false;//already loaded

    this.cacheddata = data;
    return true;
  }

  async loadPrintlist()
  {
    if( !this.printcontainer )
      return;

    this.printitems;
    if( !this.loadPrintData() )
      return;

    let data = this.cacheddata;
    if( data )
    {
      try
      {
        let item = JSON.parse(data);
        //some basic validation
        if( item && typeof item.selected == "object" && typeof item.excluded == "object" )
          this.printitems = item;
      }
      catch(e){}
    }

    if( this.printitems && this.printitems.selected.length )
    {
      let spinner = <div class="spinner"><span class="fas fa-spinner-third fa-spin" /></div>;//
      this.printcontainer.parentNode.appendChild(spinner);
      this.printcontainer.parentNode.classList.add("loading");

      let res = await service.GetPrintList(this.printitems);

      spinner.parentNode.removeChild(spinner);
      this.printcontainer.parentNode.classList.remove("loading");

      dompack.empty(this.printcontainer);
      if( res && res.items )
      {
        this.printcontainer.parentNode.classList.remove("empty");

        let container = <ul class="printlist">
                          {
                            res.items.map(function(item, index){
                              return  (<li data-id={item.id} data-type={item.type}>
                                         <a href={item.link}>{item.prefix + ' ' + item.title}</a><br />
                                         {item.excludepages > 0
                                           ? <i>{item.excludepages + " excluded page(s)"}</i>
                                           : null
                                         }
                                         {item.excludeanchors > 0
                                           ? <i>{item.excludeanchors + " excluded paragraph(s)"}</i>
                                           : null
                                         }
                                         <span class="remove far fa-times"></span>
                                       </li>)
                            })
                          }
                        </ul>;//

        this.printcontainer.appendChild(container);
      }
      else
      {
        this.printcontainer.parentNode.classList.add("empty");
        this.printcontainer.appendChild( <i>No items found</i> );//
      }
    }
    else
      this.setEmptyPrintList();
  }

  setEmptyPrintList()
  {
    dompack.empty(this.printcontainer);
    this.printcontainer.parentNode.classList.add("empty");
    this.printcontainer.appendChild( <i>No items selected</i> );//
  }

  handlePrintActions(ev)
  {
    if( dompack.closest(ev.target, ".remove-all") )
    {
      ev.preventDefault();

      dompack.dispatchCustomEvent(window, "ccoe:print-deleteall", { bubbles: false
                                                                  , cancelable: true
                                                                  , detail: {}
                                                                  });

      this.setEmptyPrintList();

      this.cacheddata = null;//force reload content printlist if called

      return;
    }

    let delitem = dompack.closest(ev.target, ".printlist .remove");
    if( delitem )
    {
      ev.preventDefault();

      let listitem = delitem.parentNode;
      let listnode = dompack.closest(delitem, "ul");

      dompack.dispatchCustomEvent(window, "ccoe:print-deleteitem", { bubbles: false
                                                                   , cancelable: true
                                                                   , detail: { printid: listitem.dataset.id
                                                                             , printtype: listitem.dataset.type
                                                                             }
                                                                   });
      listitem.parentNode.removeChild(listitem);
      if( listnode.children.length == 0 )
        this.setEmptyPrintList();

      this.cacheddata = null;//force reload content printlist if called

      return;
    }

    if( dompack.closest(ev.target, ".print-selected") )
    {
      if( !this.printform || !this.printitems || !this.printitems.selected.length)
      {
        ev.preventDefault();
        return;
      }

      this.printform.printdata.value = this.cacheddata;
      this.printform.printaction.value = "printselected";
      this.printform.target = "print";
      return;
    }

    if( dompack.closest(ev.target, ".download-selected") )
    {
      if( !this.printform || !this.printitems || !this.printitems.selected.length)
      {
        ev.preventDefault();
        return;
      }

      this.printform.printdata.value = this.cacheddata;
      this.printform.printaction.value = "downloadselected";
      this.printform.target = "";
      return;
    }

    if( dompack.closest(ev.target, ".print-all") )
    {
      if( !this.printform )
      {
        ev.preventDefault();
        return;
      }

      this.printform.printdata.value = "";
      this.printform.printaction.value = "printall";
      this.printform.target = "print";
      return;
    }
  }
}

dompack.register(".toolbar", node => new cToolbar(node) );
