import * as dompack from "dompack";
import * as forms from '@mod-publisher/js/forms';
import UploadField from '@mod-publisher/js/forms/fields/upload';
import { DateField, TimeField } from '@mod-publisher/js/forms/fields/datetime';
import ImgEditField from '@mod-publisher/js/forms/fields/imgedit';
import RTDField from '@mod-publisher/js/forms/fields/rtd';
import './forms.scss';
import './datetime.scss';
/*
import * as googleRecaptcha from "@mod-publisher/js/captcha/google-recaptcha";
import * as dialog from 'dompack/components/dialog';
import * as dialogapi from 'dompack/api/dialog';
dialogapi.setupDialogs(options => dialog.createDialog('ccoe-dialog', options));//needed for recaptcha
*/

//Enable forms and our builtin validation
forms.setup({validate:true});

//Replaces upload fields with a nicer and edit-supporting version
dompack.register(".wh-form__upload", node => new UploadField(node));

//Replaces date fields with a split version
dompack.register(".wh-form__date", node => new DateField(node));
dompack.register(".wh-form__time", node => new TimeField(node));

//Enable the imgedit and/or rtd fields:
dompack.register(".wh-form__imgedit", node => new ImgEditField(node));
dompack.register(".wh-form__rtd", node => new RTDField(node));

//enable the line below AND the googleRecaptcha import if you want to use this recaptcha. you'll also need to enable it in the site profile
//googleRecaptcha.setupGoogleRecaptcha();

//Styled pulldown: add wrapper around select element + arrow for css-styling
dompack.register(".wh-form select", selectnode => {
  let wrappernode = <div class="wh-form__pulldown-styled" />;
  selectnode.parentNode.insertBefore(wrappernode, selectnode);
  wrappernode.appendChild(selectnode);
  wrappernode.appendChild(<span class="arrow far fa-angle-down" />);
});

function toggleBusyOverlay( detail )
{
  if( detail.islock )
  {
    let titlenode = document.querySelector(".rpc__busyoverlay .title");
    if(titlenode)
      titlenode.textContent = detail.title ? detail.title : "";

    document.documentElement.classList.add("dompack--busymodal");
  }
  else
    document.documentElement.classList.remove("dompack--busymodal");
}

//Toggle overlay when form is submitting
window.addEventListener("dompack:busycomponent", ev => toggleBusyOverlay( ev.detail ) );
