import "./video.css";
import * as dompack from 'dompack';

function playVideo(ev,  node)
{ // Uses the default video player loaded by rtd (@mod-publisher/js/richcontent/all)
  if( !node )
    node = this;

  if( node.classList.contains("wh-video") )
    return;

  node.classList.add("wh-video");
  dompack.registerMissed(node);
  node.removeEventListener("click", playVideo);
}

dompack.register(".embeddedwidget-video__player", widget => { widget.addEventListener("click", playVideo ); });
