import "./aside.scss";
import * as dompack from 'dompack';

//Foldin/out toggle
dompack.register(".mainnav .section > .arrow", node => {
  let section = dompack.closest(node, ".section");
  node.addEventListener("click", ev => {
    ev.preventDefault();
    section.parentNode.classList.toggle("show");
  });
});
