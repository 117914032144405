import * as dompack from 'dompack';
import 'dompack/browserfix/reset.css';

//import * as whintegration from '@mod-system/js/wh/integration';
import '@mod-system/js/wh/errorreporting'; //log JS errors to notice log
import { openLinksInNewWindow } from '@mod-publisher/js/linkhandler';
import './site.lang.json';
import './shared/forms/forms';
import './shared/rtd/rtd';
import './cimichandbook.scss';
import './shared/summary.scss';
import './widgets';
import './pages';

import './header';
import './aside';
import './footer';

window.event_supportstouch = "ontouchstart" in window || "createTouch" in document || "TouchEvent" in window ||
                 (window.DocumentTouch && document instanceof window.DocumentTouch) ||
                 navigator.maxTouchPoints > 0 ||
                 window.navigator.msMaxTouchPoints > 0;

window.event_supportspassive = false;
document.createElement("div").addEventListener("test", _ => {}, { get passive() { window.event_supportspassive = true; }});

dompack.onDomReady(() =>
{
  openLinksInNewWindow({ extensions : ["pdf"] });
  document.documentElement.classList.add("loaded");//used to enable css link color transition after page is loaded
});
