import '@mod-publisher/js/richcontent/all';
import './rtd.css';
import * as dompack from 'dompack';
import * as whintegration from '@mod-system/js/wh/integration';
import {getSafeFileName} from '../support.es';

let __cookiename = "printids";

class cPrintSelector
{
  constructor()
  {
    //Get info about selected items and show counter top right in header
    this.countnode = document.querySelector(".toolbar__item--print .count");
    this.getPrintItems();

    window.addEventListener("ccoe:print-deleteall", ev => this.reset() );
    window.addEventListener("ccoe:print-deleteitem", ev => this.removeFromPrintList( ev.detail.printid, ev.detail.printtype ) );

    this.ismanualpage = document.documentElement.classList.contains("manual");
    if( this.ismanualpage )
      this.initManualPage();
  }

  reset()
  {
    this.printitems = { "selected" : [], "excluded" : [] };
    this.markSelectedItems();
    this.updateCounter();
    this.savePrintItems();
  }

  initManualPage()
  {
    //Section h1 (folder)
    this.printsectionnode = document.querySelector("article h1.sectiontitle > .printbutton");
    if( this.printsectionnode )
      this.initPrintNode( this.printsectionnode );

    //Page title h2 (file)
    this.printpagenode = document.querySelector("article h2.pagetitle > .printbutton");
    if( this.printpagenode )
      this.initPrintNode( this.printpagenode );

    //Anchors h3 in page
    let printidprefix = whintegration.config.obj.rfid + ":" + whintegration.config.obj.pageid + ":";
    this.printanchornodes = [];
    let anchornr = 0;
    for( let hnode of document.querySelectorAll("article > h3") )
    {
      let node = <a class="wh-rtd__anchor" id={getSafeFileName(hnode.textContent)} />;//
      hnode.appendChild(node);

      ++anchornr;//Autonumber with pageprefix at start
      if( whintegration.config.obj.pageprefix )
      {
        let pnode = node.parentNode;
        let txtnode = <span>{whintegration.config.obj.pageprefix + anchornr + ". " + pnode.textContent}</span>;//
        pnode.textContent = "";
        pnode.appendChild(node);//reattach anchor
        pnode.appendChild(txtnode);//append text after anchor
      }

      let printnode = <span data-id={printidprefix + node.id} data-type="anchor" class="printbutton">
                        <span class="text">
                          <span class="fas fa-check"></span>
                          selected for printing
                        </span>
                        <span class="icon far fa-print" />
                      </span>;//

      node.parentNode.appendChild( printnode );
      this.printanchornodes.push( printnode );

      this.initPrintNode(printnode);
    }

    if( anchornr > 0 )
      this.initScrollTracker();

    this.markSelectedItems();
  }

  initScrollTracker()
  {
    this.currentidx = -1;
    //Active menu is used to highlight child element depending on anchor in view
    this.activemenu = document.getElementById("anchorlist" + whintegration.config.obj.rfid + "_" + whintegration.config.obj.pageid);
    window.addEventListener("resize", ev => this.onResize());
    window.addEventListener("load", ev => this.onResize());
    this.onResize();

    if( window.event_supportspassive )
      window.addEventListener("scroll", ev => this.onScroll(), { passive: true } );
    else
      window.addEventListener("scroll", ev => this.onScroll() );
  }

  onResize()
  {
    this.screeny = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
    this.centery = this.screeny/2;

    this.onScroll();//Update highlight
  }

  onScroll()
  {
    let idx = -1;
    let lastdy = -1;
    for( let i = 0; i < this.printanchornodes.length; ++i )
    {
      let y = this.printanchornodes[i].getBoundingClientRect().top;
      if( y < this.centery )
      {
        let dy = Math.abs(y - this.centery);
        if( lastdy < 0 || dy < lastdy )
        {
          idx = i;
          lastdy = dy;
        }
      }
    }

    if( idx == this.currentidx )
      return;

    if( this.currentidx > -1 && this.currentidx < this.activemenu.children.length )
      this.activemenu.children[this.currentidx].classList.remove("active");

    if( idx > -1 && idx < this.activemenu.children.length )
      this.activemenu.children[idx].classList.add("active");

    this.currentidx = idx;
  }

  getPrintItems()
  {
    this.printitems = { "selected" : [], "excluded" : [] };//default/initial

    let data = localStorage.getItem(__cookiename);
    if( data )
    {
      try
      {
        let item = JSON.parse(data);
        //basic validation
        if( item && typeof item.selected == "object" && typeof item.excluded == "object" )
        {
          this.printitems = item;
          this.updateCounter();
          return;
        }
      }
      catch(e){}
    }

    this.updateCounter();
    this.savePrintItems();
  }

  savePrintItems()
  {
    localStorage.setItem(__cookiename, JSON.stringify(this.printitems) );
  }

  initPrintNode(printnode)
  {
    printnode.addEventListener("click", ev => {
      if( this.onPrintlist(printnode.dataset.id) )
        this.removeFromPrintList(printnode.dataset.id, printnode.dataset.type);
      else
        this.addToPrintList(printnode.dataset.id, printnode.dataset.type);
    });
  }

  markSelectedItems()
  {
    if( !this.ismanualpage )
      return;

    if( this.printsectionnode )
      dompack.toggleClass(this.printsectionnode.parentNode, "print", this.onPrintlist(this.printsectionnode.dataset.id) );

    if( this.printpagenode )
      dompack.toggleClass(this.printpagenode.parentNode, "print", this.onPrintlist(this.printpagenode.dataset.id) );

    for( let i = 0; i < this.printanchornodes.length; ++i )
      dompack.toggleClass(this.printanchornodes[i].parentNode, "print", this.onPrintlist(this.printanchornodes[i].dataset.id) );
  }

  onPrintlist( id )
  {
    if( this.printitems.selected.indexOf(id) != -1 ) //exact match
      return true;

    for( let i = 0; i < this.printitems.excluded.length; ++i )
    {
      if( (id + ":").indexOf(this.printitems.excluded[i] + ":") == 0 )
        return false;
    }

    for( let i = 0; i < this.printitems.selected.length; ++i )
    {
      if( (id + ":").indexOf(this.printitems.selected[i] + ":") == 0 )
        return true;
    }

    return false;
  }

  getIdParts( id )
  {
    let idparts = { section : ""
                  , page : ""
                  , anchor : ""
                  };
    let parts = id.split(":");
    if( parts.length > 0 && parts[0] ) //must have sectionid
      idparts.section = parts[0];
    if( parts.length > 1 && idparts.section )
      idparts.page = parts[1];
    if( parts.length > 2 && idparts.section && idparts.page )
      idparts.anchor = (parts.splice(2)).join(":");

    return idparts;
  }

  addToPrintList( printid, printtype )
  {
    //First check if in excludelist and remove from list if so
    let excludeidx = this.printitems.excluded.indexOf(printid);
    if( excludeidx != -1 )
      this.printitems.excluded.splice(excludeidx, 1);

    //split selected id in it path parts
    let idparts = this.getIdParts(printid);

    if( !idparts.section )
      return;//must at least have sectionid

    if( printtype == "section" )
    {
      this.printitems.selected.push( printid );

      //cleanup selected list
      for( let i = this.printitems.selected.length - 1; i >= 0; --i )
      {
        if( this.printitems.selected[i].indexOf(idparts.section + ":") != -1 )
          this.printitems.selected.splice(i, 1);
      }
    }
    else if( printtype == "page" && idparts.page )
    { //page
      if( this.printitems.selected.indexOf(idparts.section) == -1 )//no whole section selected then add pageid
        this.printitems.selected.push( printid );

      //cleanup selected list
      for( let i = this.printitems.selected.length - 1; i >= 0; --i )
      {
        if( this.printitems.selected[i].indexOf(idparts.section + ":" + idparts.page + ":") != -1 )
          this.printitems.selected.splice(i, 1);
      }
    }
    else if( printtype == "anchor" && idparts.anchor && idparts.page )
    {
      //check if section or page is selected else add id
      if( !this.onPrintlist( printid) )
        this.printitems.selected.push( printid );
    }
    else
      return;

    this.markSelectedItems();
    this.updateCounter();
    this.savePrintItems();
  }

  removeFromPrintList( printid, printtype )
  {
    if( !printid || !printtype )
      return;

    //split selected id in it path parts
    let idparts = this.getIdParts( printid );

    if( printtype == "section" )
    {
      //Remove current section and everything in it from list
      for( let i = this.printitems.selected.length - 1; i >= 0; --i )
      {
        if( (this.printitems.selected[i] + ":").indexOf(idparts.section + ":" ) != -1 )
          this.printitems.selected.splice(i, 1);
      }

      //section can not be excluded

      //cleanup exclude list
      for( let i = this.printitems.excluded.length - 1; i >= 0; --i )
      {
        if( this.printitems.excluded[i].indexOf(idparts.section + ":") != -1 )
          this.printitems.excluded.splice(i, 1);
      }
    }
    else if( printtype == "page" && idparts.page )
    {
      //Remove current page and everything in it from list
      for( let i = this.printitems.selected.length - 1; i >= 0; --i )
      {
        if( (this.printitems.selected[i] + ":").indexOf(idparts.section + ":" + idparts.page + ":") != -1 )
          this.printitems.selected.splice(i, 1);
      }

      // if section is set, set exclude list
      let idx = this.printitems.selected.indexOf( printid );
      if( idx > -1 )
        this.printitems.selected.splice(idx, 1);
      else
        this.printitems.excluded.push( printid );

      //cleanup exclude list
      for( let i = this.printitems.excluded.length - 1; i >= 0; --i )
      {
        if( this.printitems.excluded[i].indexOf(idparts.section + ":" + idparts.page + ":") != -1 )
          this.printitems.excluded.splice(i, 1);
      }
    }
    else if( printtype == "anchor" && idparts.anchor && idparts.page )
    { //anchor
      // if section or page is set, set exclude list
      let idx = this.printitems.selected.indexOf( printid );
      if( idx > -1 )
        this.printitems.selected.splice(idx, 1);
      else
        this.printitems.excluded.push( printid );
    }

    this.markSelectedItems();
    this.updateCounter();
    this.savePrintItems();
  }

  updateCounter()
  {
    if( this.countnode )
      this.countnode.textContent = this.printitems.selected.length ? this.printitems.selected.length : "";
  }
}

dompack.onDomReady(() =>
{
  new cPrintSelector();
});
